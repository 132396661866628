import * as React from "react";
import Grid from "@mui/material/Grid";
import theme from "../theme";
import Box from "@mui/material/Box";
import useMediaQuery from '@mui/material/useMediaQuery';
import Hero from './Hero';



interface PageRowProps {
    imageLeftOrRight?: string,
    imagePath?:string,
    children?:any,
    smallScreen?:boolean,
    carouselPath?:string,
    topMarginAdjust?:number
}

interface PageRowState {

}

export default function PageRowWrapper({ ...rest }) {
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});
    return <PageRow {...rest} smallScreen={smallScreen} />;
}

class PageRow extends React.Component <PageRowProps, PageRowState> {

    textTheme = {
        color: theme.palette.secondary.light,
        verticalAlign: "middle"
    };

    smallTextTheme = {
        color: this.textTheme.color,
        verticalAlign: this.textTheme.verticalAlign,
        paddingLeft: 5,
        paddingRight: 5
    };

    rowTheme = {
        marginTop: 10
    }

    imageStyle = {
        height: 250
    }

    imageStyleLeft = {
        width: this.imageStyle.height,
        marginRight: 10
    }

    imageStyleRight = {
        width: this.imageStyle.height,
        marginLeft: 10
    }

    imageStyleVertical = {
        width: this.imageStyle.height,
        align: "center"
    }

    carouselContainer = {

    };



    constructor (props) {
        super(props);
        if (this.props.smallScreen && this.props.topMarginAdjust) {
            this.rowTheme.marginTop=this.props.topMarginAdjust;
        }
    }

    getImageStyle(imagePosition:string) {
        switch (imagePosition) {
            case 'right':
                return this.imageStyleRight;
            case 'left':
                return this.imageStyleLeft;
            case 'vertical':
                return this.imageStyleVertical;
        }
    }

    getImageTag (imagePosition:string) {
        const imageStyle=this.getImageStyle(imagePosition);

        if (this.props.carouselPath) {
            return (
                <Grid sx={this.carouselContainer} item lg={4} sm={12}>
                    <Hero imagePath={this.props.carouselPath} width={200}/>
                </Grid>
            );
        }
        else {
            return (
                <Grid item lg={4} sm={12}>
                    <Box component="img" src={this.props.imagePath} sx={imageStyle}/>
                </Grid>
            );
        }
    }

    showImage (leftOrRight:string) {

        if (this.props.imageLeftOrRight === 'right' && leftOrRight === 'right') {
            return (this.getImageTag("right"));
        }
        if (this.props.imageLeftOrRight === 'left' && leftOrRight === 'left') {
            return (this.getImageTag("left"));
        }
        return (
            <div></div>
        );
    }

    render () {
        if (this.props.smallScreen) {
            return (
                <Grid container sx={this.rowTheme} alignItems="center" justifyItems="center" direction="column">
                    <Grid item sm={12}>
                        {this.getImageTag("vertical")}
                    </Grid>
                    <Grid item sx={this.smallTextTheme} sm={8}>
                        {this.props.children}
                    </Grid>
                </Grid>
            );
        }
        else {
            return (

                <Grid container sx={this.rowTheme} alignItems="center" justifyItems="center">
                    <Grid item lg={2}></Grid>
                    {this.showImage('left')}
                    <Grid item sx={this.textTheme} lg={4}>
                        {this.props.children}
                    </Grid>
                    {this.showImage('right')}
                </Grid>

            );
        }
    }
}