import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

let domElement : Element = document.getElementById('root') as HTMLInputElement;

ReactDOM.render(<React.StrictMode>
        <App />
    </React.StrictMode>,domElement
);


