import React from "react";
import './App.css';
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import OurHistory from "./views/OurHistory";


export default class App extends React.Component {

    constructor(props) {
        super(props);
    }

    render () {
        return (

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/ourHistory" element={<OurHistory/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        );
    }
}

