import * as React from 'react';
import withRoot from '../withRoot';
import CbnTopBar from "./CbnTopBar";
import Hero from "./Hero";
import TextBlock from "./TextBlock";
import Box from "@mui/material/Box";
import theme from "../theme";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import Background from "./Background";
import {Button, Hidden} from "@mui/material";
import Markdown from "markdown-to-jsx";
import Typography from "@mui/material/Typography";

function Index() {

    const backgroundStyle = {
        zIndex:-1,
        position: "absolute",
        top: 0
    }

    const [backgroundHeight,setBackgroundHeight]=useState(0);

    useEffect(()=> {
        const pageGridElement = document.getElementById("pageGrid");
        setTimeout(()=>{
            setBackgroundHeight(document.body.scrollHeight);
        },50);
    });


  return (
      <React.Fragment>
          <Hidden smDown={true}>
            <CbnTopBar />
          </Hidden>
          <Box sx={backgroundStyle}>
              <Background height={backgroundHeight}/>
          </Box>
          <Grid container alignItems="center">
              <Grid item lg={12}>
                  <Hero imagePath="heros" />
              </Grid>
              <Grid item>
                  <Grid container>
                      <Grid item lg={2}>

                      </Grid>
                      <Grid item lg={8}>
                          <Typography sx={
                              { color: theme.palette.secondary.light,
                                  paddingLeft: 5,
                                  paddingRight: 5
                              }}>
                              <h2>The Chicago Home for Broncos Fans Since 2004</h2>
                              <p>
                                Chicago Bronco Nation was founded in 2004 by three Broncos fans living in Chicago.
                                  It started off with a meetup at a local Buffalo Wild Wings and soon took over
                                  one of the back rooms at Riverview Tavern. Over the next few years our group
                                  would grow and then eventually outgrow Riverview.
                              </p>
                              <p>
                                  In 2010 we moved to The Irish Oak and for many of us, the Oak will always feel
                                  like the home for our group. There we got to witness Tebowmania and the Manning's
                                  march to the single season TD record. Finally in 2016 we got another chance
                                  to witness a Broncos super bowl victory. When the NFL Draft came to Chicago,
                                  we were where the Broncos came to party with Miles, and even Joe Ellis stopping by.
                              </p>
                              <p>This year has been a big year of change for the Broncos with a new quarterback,
                                  coach, and even ownership. It seems appropriate then that we'd be changing to a
                                  new bar. We'll always have a fond place in our hearts for the Irish Oak which
                                  became home to so many great memories.
                              </p>
                              <h2>Why "Bronco Nation"?</h2>
                              <p>
                                  The term "Broncos Country" didn't exist until a marketing campaign by the Broncos
                                  in 2005. Had we been founded a year or two later, no doubt we'd be called Chicago
                                  Broncos Country. We remain "Bronco Nation" to honor the people who started our
                                  group and helped build it to become what it is today.
                              </p>
                          </Typography>
                      </Grid>
                      <Grid item lg={2}>

                      </Grid>
                  </Grid>

              </Grid>
          </Grid>
          <Hidden lgUp={true}>
              <Box display="flex">
                  <Box m="auto">
                      <Button variant="contained" href="/">Home</Button>
                  </Box>
              </Box>
          </Hidden>


      </React.Fragment>
  );
}

export default withRoot(Index);
