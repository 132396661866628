import * as React from 'react';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Button, Menu, MenuItem, Typography} from "@mui/material";
import IconButton from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";



function CbnTopBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuStyle = {
      flexGrow: .07
  };

  return (
      <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
              <Toolbar>
                  <Typography variant="h6" sx={{ flexGrow: 1 }} color="secondary.light"><Link color="secondary.light" component={RouterLink} sx={{textDecoration: 'none'}} to="/">CBN</Link></Typography>
                  <Typography variant="h6" sx={menuStyle}><Link color="secondary.light" sx={{textDecoration: 'none'}} href="https://www.facebook.com/groups/chicagobronconation/">Facebook Group</Link></Typography>
                  <Typography variant="h6" sx={menuStyle}><Link color="secondary.light" sx={{textDecoration: 'none'}} href="https://www.almosthometavern.com/">Almost Home</Link></Typography>
                  <Typography variant="h6" sx={menuStyle}><Link color="secondary.light" component={RouterLink} sx={{textDecoration: 'none'}} to="/ourHistory">Our History</Link></Typography>

              </Toolbar>
          </AppBar>
      </Box>

  );
}

export default CbnTopBar;
