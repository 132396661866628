import React,{useState,useEffect} from 'react';
import withRoot from '../withRoot';
import HypeBlock from "./HypeBlock";
import theme from "../theme";
import Box from "@mui/material/Box";
import CbnTopBar from "./CbnTopBar";
import {Button, Hidden, Typography} from "@mui/material";
import PageRow from "./PageRow";
import Grid from "@mui/material/Grid";
import Background from "./Background";


function Index() {
    const bodyStyle = {
    };

    const backgroundStyle = {
        zIndex:-1,
        position: "absolute",
        top: 0
    }

    const [backgroundHeight,setBackgroundHeight]=useState(0);


    useEffect(()=> {
        const pageGridElement = document.getElementById("pageGrid");
        setTimeout(()=>{
            setBackgroundHeight(document.body.scrollHeight);
        },700);
    });

  return (
    <React.Fragment>
        <Box style={bodyStyle} >
            <Hidden smDown={true}>
                <CbnTopBar/>
            </Hidden>
            <Box sx={backgroundStyle}>
                <Background height={backgroundHeight}/>
            </Box>
            <Grid container alignItems="center" id="pageGrid">
                <Grid item lg={1} sm={0}>

                </Grid>
                <Grid item lg={10} sm={12}>
                    <PageRow imageLeftOrRight="right" imagePath="/images/CBN_LOGO_large.png">
                        <Typography variant="h2">Your Home Away From Mile High</Typography>
                        <p>
                            Join Chicago's largest Broncos fan group, connect on Facebook &
                            watch every game at Almost Home in Wrigleyville!
                        </p>
                    </PageRow>
                    <PageRow imageLeftOrRight="left" imagePath="/images/cbnfb.png" topMarginAdjust={-.5}>
                        <Typography variant="h3">Join Our Facebook Group</Typography>
                        <p>
                            Come and contribute to Broncos discussions and debate how terrible the Raiders are.
                            Or just creep in the comments. We don’t care. We're over 500 strong!
                        </p>
                        <Box display="flex">
                            <Box m="auto">
                                <Button variant="contained" href="https://www.facebook.com/groups/chicagobronconation/">Join Now</Button>
                            </Box>
                        </Box>
                        <Box sx={{backgroundColor: "#F00"}} justifyContent="center" alignItems="center" m="auto">

                        </Box>
                    </PageRow>
                    <PageRow imageLeftOrRight="right" imagePath="/images/almost_home.png">
                        <Typography variant="h3">Watch every live game at
                            Almost Home in Wrigleyville</Typography>
                        <p>
                            Almost Home is the place to be on Broncos game day!  They are one of the newer bars in Wrigleyville
                            and they know what fun Chicago Bronco Nation can be.
                        </p>
                        <Box display="flex">
                            <Box m="auto">
                                <Button variant="contained" href="https://www.almosthometavern.com/">Get Directions</Button>
                            </Box>
                        </Box>
                    </PageRow>
                    <PageRow imageLeftOrRight="left" carouselPath="mini_heros">
                        <Typography variant="h3">Connect for away games</Typography>
                        <p>
                            Every year a group of us coordinate to travel to at least one away game a season.  In past seasons
                            we've been to Nashville, NOLA, Cincinnati, St. Louis & of course...Mile High!
                        </p>
                    </PageRow>
                    <PageRow imageLeftOrRight="right" imagePath="/images/BRONCOS_old_schoo_shadowl.png">
                        <Typography variant="h3">Our History</Typography>
                        <p>
                           We started in 2004 as a meetup for a few fans at a Buffalo Wild Wings. Since then we've become one of the
                           biggest NFL fan communities in Chicago. In 2010, The Irish Oak became our home, and people lined up
                            around the block to be a part of our Super Bowl runs.
                        </p>
                        <p>
                            Now we've found our home at Almost Home!
                        </p>
                        <Box display="flex">
                            <Box m="auto">
                                <Button variant="contained" href="/ourHistory">Learn More</Button>
                            </Box>
                        </Box>

                    </PageRow>
                </Grid>
                <Grid item lg={1} sm={0}>

                </Grid>
            </Grid>

        </Box>

    </React.Fragment>
  );
}

export default withRoot(Index);
