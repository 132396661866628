import * as React from 'react';
import theme from "../theme";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";


interface HeroProps {
    imagePath: string,
    width?: number,
}

export default class Hero extends React.Component <HeroProps> {


    styling={
        backgroundColor: theme.palette.secondary.main,
        // Average color of the background image.
        backgroundPosition: 'center',
        position: 'relative'

    };

    importImages(path) : Array<string> {
        const allImages = require.context('../../public/images/',true, /\.(png|jpg|svg)$/);
        let images = [];
        allImages.keys().map((item, index) => {
            if (item.startsWith(`./${path}`)) {
                images.push(item);
            }
        });
        this.shuffleArray(images);
        return images;
    }

    shuffleArray (array: Array<string>)  {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }


    getCarouselImages (): Array<JSX.Element> {

        const images = this.importImages(this.props.imagePath);

        let carouselImages: Array<JSX.Element> = [];
        let index=0;
        const imageStyle={
            borderRadius: "0px"
        };
        for (let image of images) {
                if (this.props.width) {
                    imageStyle.borderRadius="10px";
                }
                else {
                    imageStyle.borderRadius='0px';
                }
                carouselImages.push(
                    <Box key={index++}>
                        <img src={`images/${image}`}  style={imageStyle}/>
                    </Box>
                );
        }
        return carouselImages;
    }

    formatter () {
        return '';
    }

    render () {
        return (
            <Box sx={this.styling}>
                <Carousel width={this.props.width} statusFormatter={this.formatter} autoPlay={true} showThumbs={false} infiniteLoop={true} interval={5000} showIndicators={false}>
                    {this.getCarouselImages()}
                </Carousel>
            </Box>
        );
    }
}


