import React, {ReactNode} from "react";
import Box from "@mui/material/Box";
import theme from "../theme";

interface BackgroundProps {
    children?: any,
    height?:number
}

interface BackgroundState {

}

export default class Background extends React.Component <BackgroundProps, BackgroundState> {

    initialHeight:number=0;

    constructor (props) {
        super(props);
    }

    transparentBackground="rgba(255, 245, 248,.25)";

    containerStyle = {
        backgroundColor: theme.palette.secondary.main,
        position: "absolute"
    }

    circleCommon = {
        backgroundImage: `radial-gradient(circle, ${this.transparentBackground} 10%, transparent 70%)`,
        height: "70vw",
        width: "70vw",
        zIndex: -1
    }

    circleOdd = {
        backgroundImage: this.circleCommon.backgroundImage,
        height: this.circleCommon.height,
        width: this.circleCommon.width,
        zIndex: this.circleCommon.zIndex,
        float: "right",
        marginRight: "-40vw",
        marginTop: "+40vw"
    }

    circleEven = {
        backgroundImage: this.circleCommon.backgroundImage,
        height: this.circleCommon.height,
        width: this.circleCommon.width,
        zIndex: this.circleCommon.zIndex,
        marginTop: "-40vw",
        marginLeft: "-40vw",
        float: "left",
        clear: "both"

    }

    async componentDidMount () {
        if (this.initialHeight===0) {
            const backgroundContainer=document.getElementById("backgroundContainer");
            this.initialHeight=backgroundContainer.clientHeight;
        }
    }

    rowStyle = {
        width: "100vw"
    }

    renderBoxes () : ReactNode [] {
        let boxCollection: ReactNode[] = [];
        let style={};
        let totalHeight=this.props.height;
        let viewHeight=window.innerHeight;
        let iterations=0;
        if (this.initialHeight===0) {
            iterations=2;
        }
        else {
            let ratio = Math.ceil(totalHeight / this.initialHeight);
            iterations = 2*ratio;
        }
        for (let x=0;x<iterations;x++) {
            if (x%2===0) {
                style=this.circleEven;
                boxCollection.push(
                    <Box sx={this.rowStyle} key={x}>
                        <Box sx={style}></Box>
                    </Box>
                );
                boxCollection.push();
            }
            else {
                style=this.circleOdd;
                boxCollection.push(
                    <Box sx={this.rowStyle} key={x}>
                        <Box sx={style}></Box>
                    </Box>
                );
            }
        }
        return boxCollection;
    }

    render () {
        return (
          <Box sx={this.containerStyle} id="backgroundContainer">
              {this.renderBoxes()}
          </Box>
        );
    }
}