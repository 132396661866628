import React from "react";
import Typography from "@mui/material/Typography";
import theme from "../theme";
import Markdown from "markdown-to-jsx";

export default class NotFound extends React.Component {

    render () {
        return (
            <Typography sx={
                { color: theme.palette.secondary.light,
                    paddingLeft: 5,
                    paddingRight: 5
                }} variant="h2">
                That URL was IN-COM-PLETE!
            </Typography>

        );
    }

}